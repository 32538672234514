@import '../../styles/index';

.base {
  display: flex;
  align-items: center;
  background-color: $color-surrogate--100;
  padding: 1rem;
  min-height: 60px;

  .navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: $color-white--100;
    font-size: 1rem;
    line-height: 20px;

    .buttons {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;

      .prev,
      .next {
        cursor: pointer;

        &.disabled {
          opacity: 0.5;
          cursor: default;
        }
      }

      .prev {
        margin-right: 1.2rem;
      }
    }
  }
}
