@import '../../styles/index';

.base {
  margin: 0;
  padding: 1rem;
  background-color: $color-white--100;
  cursor: pointer;
  border-bottom: 1px solid $color-surrogate--20;

  &:hover {
    background-color: $color-surrogate--05;
  }

  @include media-from(sm) {
    padding: 1.5rem;
  }
}

.title {
  font-size: $font-size-18;
  font-weight: $font-weight-medium;
  line-height: 1.5;
  margin: 0;

  @include media-from(sm) {
    font-size: $font-size-20;
  }
}

.services {
  font-size: $font-size-16;
  font-weight: $font-weight-light;
  line-height: 1.375;
  margin: 0 0 1rem;
}

.address {
  display: flex;
  align-items: center;
  font-size: $font-size-16;
  font-weight: $font-weight-light;
  margin: 0 0 0.5rem;
  line-height: 1.375;
  color: $color-black--100;

  .icon {
    color: $color-surrogate--100;
    margin-right: 0.5rem;
  }
}
