@import '../../styles/index';

.base {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: $color-white--100;
  z-index: $index-map-menu-fullscreen;

  @include media-from(sm) {
    position: relative;
    overflow: hidden;
    z-index: $index-map-menu;
    box-shadow: $shadow-eng-map-menu;
  }
}

.list-header {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70px;
  background: $color-white--100;
  padding: 0 1.25rem;
  font-size: $font-size-18;
  font-weight: $font-weight-medium;
  box-shadow: $shadow-eng-map-menu;

  &-icon {
    position: absolute;
    color: $color-surrogate--100;
    left: 1.25rem;
    cursor: pointer;
    user-select: none;
  }

  @include media-from(sm) {
    display: none;
  }
}
